//prototype to make active labels in forms
jQuery.fn.extend({
	active_label: function(defaults) {
	  	//define default values
	  	defaults = typeof defaults === 'undefined' ? {} : defaults;
	  	$(this).addClass('active_label');
	  	var label;
		$(this).find('input, textarea').on('keyup change', function(){
			label = $('label[for="' + $(this).attr('id') + '"]');
			if($(this).val() !== '') {
				$(this).addClass('active_input');
				label.addClass('active_label');
			}
			else {
				$(this).removeClass('active_input');
				label.removeClass('active_label');
			}
		});
	}
});